<template>
    <div>
        <div class="main-wrapper">

        <!-- navigation -->
        <nav class="navigation scroll-bar" v-bind:class="{'nav-active': isNavbarActive, 'menu-active': isMenuActive}" style="z-index: 999999">
            <div class="container pl-0 pr-0">
                <div class="nav-content">
                    <div class="nav-top">
                        <router-link to="/admin"><div class="display1-size mr-2 ml-4 mb-1"><img :src="'/images/logo-1-new.png'" width="40"></div><span class="d-inline-block fredoka-font ls-3 fw-600 text-current font-xl logo-text mb-0">Elingway. </span> </router-link>
                        <a href="#" class="close-nav d-inline-block d-lg-none" @click="toggleNavbar()"><i class="ti-close bg-grey mb-4 btn-round-sm font-xssss fw-700 text-dark ml-auto mr-2 "></i></a>
                    </div>
                    <div class="nav-caption fw-600 font-xssss text-grey-500"><span>List </span>Menu</div>
                    <ul class="mb-3">
                        <li class="logo d-none d-xl-block d-lg-block"></li>
                        <li><router-link to="/admin/dashboard" :class="{'active' : isActiveMenu('admin/dashboard')}"  class="nav-content-bttn open-font"><i class="feather-grid mr-3"></i><span>Dashboard</span></router-link></li>
                        <li><router-link to="/admin/courses" :class="{'active' : isActiveMenu('admin/course')}" class="nav-content-bttn open-font"><i class="feather-tv mr-3"></i><span>Courses</span></router-link></li>
                        <li><router-link to="/admin/calendar" :class="{'active' : isActiveMenu('admin/calendar')}"  class="nav-content-bttn open-font"><i class="feather-calendar mr-3"></i><span>Calendar</span></router-link></li>
                        <li><router-link to="/admin/inbox" :class="{'active' : isActiveMenu('admin/inbox')}"  class=" nav-content-bttn open-font"><i class="feather-inbox mr-3"></i><span>Inbox</span></router-link></li>
                        <li><router-link to="/admin/history" :class="{'active' : isActiveMenu('admin/history')}"  class="nav-content-bttn open-font"><i class="feather-clock mr-3"></i><span>History</span></router-link></li>
                    </ul>

                    <div class="nav-caption fw-600 font-xssss text-grey-500"><span></span> Other</div>
                    <ul class="mb-3">
                        <li class="logo d-none d-xl-block d-lg-block"></li>
                        <li><a href="#" class="nav-content-bttn open-font h-auto pt-2 pb-2"><i class="font-sm feather-users mr-3 text-grey-500"></i><span>Account Management</span></a></li>
                        <li><a href="#" class="nav-content-bttn open-font h-auto pt-2 pb-2"><i class="font-sm feather-user-check mr-3 text-grey-500"></i><span>Privillage User</span></a></li>
                        <li><a href="#" class="nav-content-bttn open-font h-auto pt-2 pb-2"><i class="font-sm feather-settings mr-3 text-grey-500"></i><span>Settings</span></a></li>
                        <li><a href="#" class="nav-content-bttn open-font h-auto pt-2 pb-2"><i class="font-sm feather-help-circle mr-3 text-grey-500"></i><span>Help</span></a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- navigation -->
        <!-- main content -->
        <div class="main-content" v-bind:class="{'menu-active': isMenuActive}">
            <div class="middle-sidebar-header bg-white position-sticky">
                <button class="header-menu" @click="toggleNavbar()"></button>
                <div action="#" class="float-left header-search">
                    <div class="form-group mb-0 icon-input">
                        <i class="feather-menu font-lg text-grey-400 mt-0" @click="toggleMenu()"></i>
                        <input type="text" disabled  class="bg-transparent border-0 lh-32 pt-2 pb-2 pl-5 pr-3 font-xsss fw-500 rounded-xl w350">
                    </div>
                </div>
                <ul class="d-flex ml-auto right-menu-icon">
                    <li>
                        <a href="#"><span class="dot-count bg-warning"></span><i class="feather-bell font-xl text-current"></i>
                            <div class="menu-dropdown">
                                <h4 class="fw-700 font-xs mb-4">Notification</h4>
                                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
                                </div>
                                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                                </div>

                                <div class="card bg-transparent-card w-100 border-0 pl-5 mb-3">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                                </div>
                                <div class="card bg-transparent-card w-100 border-0 pl-5">
                                    <img src="https://via.placeholder.com/50x50.png" alt="user" class="w40 position-absolute left-0 rounded-circle">
                                    <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span class="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                                    <h6 class="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li><router-link to="/message"><span class="dot-count bg-warning"></span><i class="feather-message-square font-xl text-current"></i></router-link></li>
                    <li><img src="https://via.placeholder.com/50x50.png" :alt="user.name" class="w40 rounded-circle mt--1"></li>
                    <li></li>
                </ul>
            </div>
            <div class="middle-sidebar-bottom">
                <div class="middle-sidebar-left">
                    <slot/>
                </div>
                <div class="middle-sidebar-right right-scroll-bar" v-bind:class="{'active-sidebar': isSidebarActive}">
                    <div class="middle-sidebar-right-content">

                        <div class="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0">
                            <div class="card-body p-2 d-block text-center bg-no-repeat bg-image-topcenter" style="background-image: url(images/user-pattern.png);">
                                <a href="#" class="position-absolute right-0 mr-4"><i class="feather-edit text-grey-500 font-xs"></i></a>
                                <figure class="avatar ml-auto mr-auto mb-0 mt-2 w90"><img src="https://via.placeholder.com/100x100.png" alt="image" class="float-right shadow-sm rounded-circle w-100"></figure>
                                <div class="clearfix"></div>
                                <h2 class="text-black font-xss lh-3 fw-700 mt-3 mb-1">{{user.name}}</h2>
                                <h4 class="text-grey-500 font-xssss mt-0"><span class="d-inline-block bg-success btn-round-xss m-0"></span> Available</h4>
                                <div class="clearfix"></div>
                                <div class="col-12 text-center mt-4 mb-2">
                                    <a href="#" class="p-0 ml-1 btn btn-round-md rounded-xl bg-lightblue"><i class="text-current ti-lock font-sm"></i></a>
                                    <a href="#" @click="onLogout()" class="p-0 btn p-2 lh-24 w100 ml-1 ls-3 d-inline-block rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white">Log Out</a>
                                </div>
                                <hr>

                                <div class="col-12 pl-0 mt-4 text-left">
                                    <h4 class="text-grey-800 font-xsss fw-700 mb-3 d-block">My Course <a href="#"><i class="ti-angle-right font-xsssss text-grey-700 float-right "></i></a></h4>
                                    <splide :options="options">
                                        <splide-slide><a href="#" class="btn-round-xxxl border bg-greylight"><img src="https://via.placeholder.com/50x50.png" class="p-3"></a></splide-slide>
                                        <splide-slide><a href="#" class="btn-round-xxxl border bg-greylight"><img src="https://via.placeholder.com/50x50.png" class="p-3"></a></splide-slide>
                                        <splide-slide><a href="#" class="btn-round-xxxl border bg-greylight"><img src="https://via.placeholder.com/50x50.png" class="p-3"></a></splide-slide>
                                        <splide-slide><a href="#" class="btn-round-xxxl border bg-greylight"><img src="https://via.placeholder.com/50x50.png" class="p-3"></a></splide-slide>
                                        <splide-slide><a href="#" class="btn-round-xxxl border bg-greylight"><img src="https://via.placeholder.com/50x50.png" class="p-3"></a></splide-slide>
                                    </splide>
                                </div>  

                            </div>
                        </div>

                        <div class="card overflow-hidden subscribe-widget p-3 mb-3 rounded-xxl border-0">
                            <div class="card-body d-block text-left">
                                <h1 class="text-grey-800 font-xl fw-900 mb-4 lh-3">Account Setting</h1>
                                <form action="#" class="mt-3">
                                    <div class="form-group icon-input">
                                        <i class="ti-email text-grey-500 font-sm"></i>
                                        <input type="text" class="form-control mb-2 bg-greylight border-0 style1-input pl-5" placeholder="Enail address">
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="blankCheckbox" value="option1" aria-label="">
                                        <label class="text-grey-500 font-xssss" for="blankCheckbox">By checking this box, you confirm that you have read and are agreeing to our terms of use regarding.</label>
                                    </div>
                                </form>
                                <ul class="d-flex align-items-center justify-content-between mt-3">
                                    <li><a href="#" class="btn-round-md bg-facebook"><i class="font-xs ti-facebook text-white"></i></a></li>
                                    <li><a href="#" class="btn-round-md bg-twiiter"><i class="font-xs ti-twitter-alt text-white"></i></a></li>
                                    <li><a href="#" class="btn-round-md bg-linkedin"><i class="font-xs ti-linkedin text-white"></i></a></li>
                                    <li><a href="#" class="btn-round-md bg-instagram"><i class="font-xs ti-instagram text-white"></i></a></li>
                                    <li><a href="#" class="btn-round-md bg-pinterest"><i class="font-xs ti-pinterest text-white"></i></a></li>
                                </ul>
                            </div>
                        </div>



                    </div>
                </div>
                <button class="btn bg-current btn-circle text-white btn-neutral sidebar-right btn-toggle-sidebar" @click="toggleSidebar()">
                    <i class="btn-toggle-sidebar" v-bind:class="{'ti-angle-right' : isSidebarActive === true, 'ti-angle-left' : isSidebarActive === false}"></i>  
                </button>
            </div>            
        </div>
        <!-- main content -->
        <div class="app-footer border-0 shadow-lg">
            <a href="#" class="nav-content-bttn"><i class="feather-bell"></i></a>
            <a href="#" class="nav-content-bttn" data-tab="chats"><i class="feather-message-square"></i></a>            
            <router-link to="/" class="nav-content-bttn nav-center"><i class="feather-home"></i></router-link>
            <a href="#" class="nav-content-bttn sidebar-layer"><i class="feather-layers" @click="toggleSidebar()"></i></a>
            <a href="#" class="nav-content-bttn"><img src="https://via.placeholder.com/50x50.png" alt="user" class="w30 shadow-xss"></a>
        </div>

        <div class="app-header-search">
            <form class="search-form">
                <div class="form-group searchbox mb-0 border-0 p-1">
                    <input type="text" class="form-control border-0" placeholder="Search...">
                    <i class="fas fa-search">
                        
                    </i>
                    <a href="#" class="ml-1 mt-1 d-inline-block close searchbox-close">
                        <i class="ti-close font-xs"></i>
                    </a>
                </div>
            </form>
        </div>

        </div> 
        
    </div>
</template>

<script>
import axios from 'axios'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default ({
    name: 'AdminLayout',
    data(){
        return {
            user: {},
            isSidebarActive: false,
            isNavbarActive: false,
            isMenuActive: false,
            fullpath: this.$route.path,
            options: {
                rewind : true,
                perPage : 3,
                autoplay : true,
                arrows : false,
                pagination: false,
                gap: '1rem'
            },
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_URL_API}/user`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            this.user = res.data
            
        }).catch((err) => console.error(err))
    },
    methods: {
        onLogout() {
            axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.$router.push({name: 'Login'})
            }).catch((err) => console.error(err))
        },
        toggleSidebar(){
            this.isSidebarActive = !this.isSidebarActive
        },
        toggleNavbar(){
            this.isNavbarActive = !this.isNavbarActive
        },
        toggleMenu(){
            this.isMenuActive = !this.isMenuActive
        },
        isActiveMenu(path){
            var fullPath = this.fullpath
            if(fullPath.indexOf(path) !== -1){
                return true
            } else {
                return false
            }
        }
    }
})
</script>
